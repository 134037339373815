<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import { VclList } from "vue-content-loading";
import Stat from "@/components/widgets/stat";
import Calendar from 'primevue/calendar';

export default {
  locales: {
    en: {
    },
    pt: {
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.",
      "No records found.": "Nenhum registro encontrado.",
    },
    es: {
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.",
      "No records found.": "No se encontraron registros.",
    },
  },
  components: {
    Layout,
    VclList,
    Stat,
    Calendar
  },
  data() {
    return {
      loading: true,
      errored: false,
      total: 0,
      fields: [
        { key: "username", label: "Usuário" },
        { key: "name", label: "Nome" },
        { key: "order", label: "Pedido" },
        { key: "date", label: "Data" },
        { key: "value", label: "Valor" },
        { key: "level", label: "Nível" },
        { key: "bonus", label: "Bônus" },
      ],
      items: null,

      sortBy: "name",
      sortDesc: false,
      currentPage: "1",
      perPage: "25",
      periodMonth: new Date(),

      totalBonus: 0,
    };
  },
  methods: {
    getPeriod() {
      if (this.periodMonth) {
        const date = [
          new Date(this.periodMonth).getFullYear(),
          new Date(this.periodMonth).getMonth() + 1
        ];

        return date.join('-');
      } else {
        const date = [
          new Date().getFullYear(),
          new Date().getMonth() + 1
        ];

        return date.join('-');
      }
    },
    getList() {
      this.items = null;

      this.loading = true;
      this.errored = false;
      this.empty = false;

      api
        .get("report/indicacao?date=" + this.getPeriod())
        .then((response) => {
          if (response.data.status == "success") {
            this.items = response.data.list;
            this.totalBonus = response.data.total;
          }
        })
        .catch((error) => {
          this.errored = error;
        })
        .finally(() => {
          this.loading = false;
          if (this.items == "" || this.items == null) {
            this.empty = true;
          }
        });
    },
  },
  mounted() {
    this.getList();
  },
  watch: {
    'periodMonth': function () {
      this.getList();
    },
  },
};
</script>

<template>
  <Layout>
    <div class="page-title-box d-md-flex justify-content-between mb-3">
      <div>
        <h3>Bonificações</h3>
        <p></p>
      </div>
    </div>

    <div id="dropdown" class="d-flex mb-4">
      <div class="totals">
        <h6 class="m-0">{{ this.totalBonus | currency }}</h6>
        <h6 class="m-0 text-secondary">Total de bônus</h6>
      </div>
      <b-dropdown variant="outline-light">
        <template #button-content>
          Filtrar período
          <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="m12 14.168-5.582-5.61L5 9.967 12 17l7-7-1.415-1.414L12 14.168Z" fill="currentColor"></path>
          </svg>
        </template>
        <b-dropdown-group>
          <Calendar v-model="periodMonth" view="month" dateFormat="yy-mm-dd" inline :locale="br" style="width: 250px;">
          </Calendar>
        </b-dropdown-group>
      </b-dropdown>
    </div>

    <div class="row">
      <div v-for="stat of statData" :key="stat.icon" class="col-md-4">
        <Stat :icon="stat.icon" :title="stat.title" :value="stat.value" />
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body min-vh-50">
            <div v-if="errored" class="text-center">
              {{
              t(
              "We apologize, we are unable to retrieve information at this time. Please try again later."
              )
              }}
            </div>
            <div v-else-if="empty" class="text-center">{{ t("No records found.") }}</div>
            <div v-else class="table-responsive">
              <vcl-list v-if="loading" class="col-lg-6"></vcl-list>
              <table v-else class="table table-nowrap min-vh-50">
                <thead class="thead-light">
                  <tr>
                    <th v-for="th in table.heade" :key="th">{{ t(th) }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(td, index) in items" :key="index">
                    <td v-for="(data, row) in td" :key="row" class="notranslate">
                      <div v-if="row === 'points'">
                        {{ data | points }}
                      </div>
                      <div v-if="row === 'value'">
                        {{ data | currency }}
                      </div>
                      <div v-else-if="row === 'bonus'">
                        {{ data | currency }}
                      </div>
                      <div v-else>
                        {{ data }}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
